import { Box } from '@mui/material';
import React from 'react';

export default function ServicepageHeadingRight(props) {
  return (
    <Box className='headingright'>
        <p>{props.subcontent}</p>
       
            <ul>
                <li>{props.list1}</li>
                <li>{props.list2}</li>
                <li>{props.list3}</li>
            </ul>
    </Box>
  );
}
