import { Button, Typography } from '@mui/material';
import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const HeroSection = (props) => {
  return (
    <>
      <div className='HeroSection'>
        <Typography variant='body2' className='subtitle'>World class Digital Agency</Typography>
        <Typography variant='h1' className='title' sx={{ margin: '0 auto', fontWeight: '500', fontFamily: 'Rubik', fontSize: '60px', }}>Do you have the people & processes, to automate how your business should perform online?</Typography>
        <Button variant='contained' className='primary-btn' sx={{ padding: '15px', margin: '30px 0 0 0', }}>Validate who we are <ArrowRightAltIcon /></Button>
        {props.children}
      </div>
    </>
  );
}

export default HeroSection;
