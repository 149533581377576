import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import user1 from '../assets/images/user1.png'
import user2 from '../assets/images/user2.png'

const TestimonialCard = (props) => {
  return (
    <Grid>
      <Typography variant='body1' className='testReview'>{props.Treview}</Typography>
      <Box>
        <Typography variant='h4' className='tusername'>{props.username}</Typography>
        <Typography variant='body2' className='tuserdesig'>{props.userDesignation}</Typography>
      </Box>
      <Box>
        <ul className='user-profiles'>
        <li><img src={user1} /></li>
          <li><img src={user2} /></li>
          <li><img src={user1} /></li>
          <li><img src={{user2}} /></li>
        </ul>
      </Box>
    </Grid>
  );
}

export default TestimonialCard;
