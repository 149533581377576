import { Box,  Grid, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React from 'react';
import AboutTop from './AboutTop';
import AboutBottom from './AboutBottom';

const AboutUs = () => {
  return (
    <div>
      <Grid className='about-section'>
       <AboutTop title='Want to see more projects?' buttonText='Explore Portfolio'/>
        <AboutBottom subTitle='About Us' title='Yes, We re Digital Media Agency' caption='We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.' buttonText='More about us' />
        
      </Grid>
    </div>
  );
}

export default AboutUs;
