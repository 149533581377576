import { Box, Button, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React from 'react';

const AboutTop = (props) => {
    console.log("AboutTop")
  return (
    <Box className='about-top'>
        <Typography variant='h2'>{props.title}</Typography>
        <Button variant='outlined' className='secondaryBtn'>{props.buttonText} <ArrowRightAltIcon/></Button>
        </Box>
  );
}

export default AboutTop;
