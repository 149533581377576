import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import whitehart from '../assets/images/white-hart.png'
import whiterup from '../assets/images/white-rup.png'
import whiteenter from '../assets/images/white-enter.png'
import whitecart from '../assets/images/white-cart.png'
import whitecar from '../assets/images/white-car.png'
import whitebook from '../assets/images/white-book.png'
import whitesocial from '../assets/images/white-social.png'

const BlueMarquee = (props) => {
  return (
    <Grid className='marquee-sec blue-sec'>
    <Box>
        <Typography variant='caption' className='mrTitle'>{props.SecTitle}</Typography>
        <Box className='MarResult'>
          <ul>
            <li><img src={whitehart} alt=''/></li>
            <li><img src={whiterup} alt=''/></li>
            <li><img src={whiteenter} alt=''/></li>
            <li><img src={whitecart} alt=''/></li>
            <li><img src={whitecar} alt=''/></li>
            <li><img src={whitebook} alt=''/></li>
            <li><img src={whitesocial} alt=''/></li>
          </ul>
        </Box>
    </Box>
</Grid>
  );
}

export default BlueMarquee;
