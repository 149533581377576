import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import SectionHeading from '../components/SectionHeading';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CardBox from '../components/CardBox';

const Contactus = () => {
  return (
    <Grid className='top-space'>
      <Box className='contactus_sec'>
        <Box className='main_conainer'>
          <Box className='contact_inside'>
            <Box className='contactus_left'>
              <SectionHeading Subtitle='Contact us' title='Tell us all about your project right here.' />
              <ul className='contact_info'>
                <li>
                  <Typography variant='caption' className='ct-small'>Location</Typography>
                  <Typography variant='body1' className='ct-title'>B-41, Nehru Nagar, Jaipur, India (302016)</Typography>
                </li>
                <li>
                  <Typography variant='caption' className='ct-small'>Telephone</Typography>
                  <Typography variant='body1' className='ct-title'>+91 7726-998823</Typography>
                </li>
                <li>
                  <Typography variant='caption' className='ct-small'>send us an email at</Typography>
                  <Typography variant='body1' className='ct-title'><a href='/'>sales@lexicmedia.com</a></Typography>
                </li>
              </ul>
            </Box>
            <Box className='contact-right'>
              <form>
                <Box className='form-fildes'>
                  <label>Your Name</label>
                  <input type='text' className='input-field' />
                </Box>

                <Box className='form-fildes'>
                  <label>Company Name</label>
                  <input type='text' className='input-field' />
                </Box>

                <Box className='form-fildes'>
                  <label>Email Address</label>
                  <input type='text' className='input-field' />
                </Box>

                <Box className='form-fildes'>
                  <label>Project Budget</label>
                  <Box className='boxed'>

                    <input type="radio" id="budget1" name="skills" value="Android Development" />
                    <label for="budget1">$25k - $50K</label>

                    <input type="radio" id="budget2" name="skills" value="iOS Development" />
                    <label for="budget2">$50k - $100K </label>

                    <input type="radio" id="budget3" name="skills" value="iOS Development" />
                    <label for="budget3">$100k - $300K </label>

                  </Box>
                </Box>

                <Box className='form-fildes'>
                  <label>How did you hear about us?</label>
                  <input type='text' className='input-field' />
                </Box>

                <Box className='form-fildes'>
                  <label>Project Description</label>
                  <textarea></textarea>
                </Box>
                <Box className='form-fildes'>
                  <Button variant='contained' type='submit' className='primary-btn'>Submit Now  <ArrowRightAltIcon /> </Button>
                </Box>

              </form>
            </Box>
          </Box>
        </Box>
      </Box>

      <Grid>
        <Box className='main_conainer bottomCards'>
          <Box className='insideBox'>
            <CardBox Ctitle='Rebrand' Cbody='Unlock the true Potential' BoxWidth='33%' CardClass='cardBox firstcard' />
            <CardBox Ctitle='New Idea' Cbody='Unlock the true Potential' BoxWidth='33%' CardClass='cardBox secondcard' />
            <CardBox Ctitle='Need Dedicated Resources?' Cbody='Unlock the true Potential' BoxWidth='33%' CardClass='cardBox thirdcard' />
          </Box>
        </Box>
      </Grid>

    </Grid>

  );
}

export default Contactus;
