import { Box, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const ProjectBox = ({ portfoliImg, Subtitle, Maintitle, Liclass }) => {
  const projectBoxRef = useRef(null);

  useEffect(() => {
    const projectBox = projectBoxRef.current;

    gsap.set(projectBox, {
      opacity: 0,
      y: '30%',
    });

    ScrollTrigger.batch(projectBox, {
      onEnter: (batch) =>
        gsap.to(batch, {
          opacity: 1,
          y: 0,
          stagger: {
            each: 0.25,
            from: 'start',
          },
        }),
    });

    ScrollTrigger.addEventListener('refreshInit', () =>
      gsap.set(projectBox, {
        opacity: 0,
        y: '30%',
      })
    );
  }, []);

  return (
    <li className={Liclass} ref={projectBoxRef}>
      <Box component='img' src={portfoliImg} />
      <Box className='portfolio-detail'>
        <Typography variant='caption' className='tech-name'>
          {Subtitle}
        </Typography>
        <Typography variant='body1' className='app-name'>
          {Maintitle}
        </Typography>
      </Box>
    </li>
  );
};

export default ProjectBox;
