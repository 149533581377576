import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SectionHeading from '../components/SectionHeading';
import serviceBanner from '../assets/images/service-banner.png';
import mobiIcon from '../assets/images/mobi.png';
import ServicepageHeadingLeft from '../components/ServicepageHeadingLeft';
import ServicepageHeadingRight from '../components/ServicepageHeadingRight';
import CapabilitiesBox from '../components/CapabilitiesBox';
import service1 from '../assets/images/service1.png'
import service2 from '../assets/images/service2.png'
import service3 from '../assets/images/service3.png'
import ProjectBox from '../components/ProjectBox';
import appimg from "../assets/images/app.png";
import socialmediaapp from "../assets/images/social-media-app.png";
import web from "../assets/images/web.png";
import mobiapp from "../assets/images/mobi-app.png";
import AboutTop from '../components/AboutTop';
import Testimonials from '../components/Testimonials';
import CardBox from '../components/CardBox';

export default function Service() {
  return (
    <Grid>
      <Grid className='main_conainer'>
        <Box className='top-space'>
          <SectionHeading Subtitle='Our Service' title='Software development services tailored to your business.' discriptions='Our rich design and technology expertise delivers top brands and digital experiences.' />
          <Box className='serviceBox'>
            <Typography classNam='serv-topimg' variant='body1'><img src={serviceBanner} alt='' /></Typography>
            <Box className='service-headingSec'>
              <h2 content='' className='headingtop' varian='h2'>Extraordinary Services built with expert guidance.</h2>
              <Box className='servicetopDetl'>
                <p>We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.</p>
                <Button variant='outlined' className='secondaryBtn'>Work with us <ArrowRightAltIcon /></Button>
              </Box>
            </Box>
          </Box>

        </Box>
      </Grid>

      <Box className='technoSection'>
        <Box className='main_conainer'>
          <Box className='technohead'>
            <ServicepageHeadingLeft headingicon={mobiIcon} headingTitle='Mobile App Design' Subtitle='We create software experiences that make a lasting impression.' />
            <ServicepageHeadingRight subcontent='A strategy is more than only a plan to achieve goals. For us, it is a part of the definition of a product, given after we executed extensive market research and deeply analyzed customer needs.' list1='Unify your entire organizations' list2='Data into a single' list3='Centralized point of focus' />
          </Box>

          <Box className='box-cp service_cp'>
            <ul>
              <CapabilitiesBox CpIcon={service1} Cptitle='Sub-services 1' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service2} Cptitle='Sub-services 2' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service3} Cptitle='Sub-services 3' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service1} Cptitle='Sub-services 3' Cpinfo='Building creative solutions to complex problems.' />
            </ul>
          </Box>

          <Box className='serv-casestudy'>
            <Typography variant='h5' className='casestydyTitle' sx={{ textAlign: 'center' }}>Mobile App Design Case Studies</Typography>
            <ul className="portfolio-list">
              <ProjectBox
                portfoliImg={appimg}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
              <ProjectBox
                portfoliImg={web}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
              <ProjectBox
                portfoliImg={socialmediaapp}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
            </ul>
          </Box>
          <Box sx={{ textAlign: 'center' }}><Button variant='outlined' className='secondaryBtn'>View More<ArrowRightAltIcon /></Button></Box>
        </Box>
      </Box>

      <Box className='technoSection'>
        <Box className='main_conainer'>
          <Box className='technohead'>
            <ServicepageHeadingLeft headingicon={mobiIcon} headingTitle='Mobile App Design' Subtitle='We create software experiences that make a lasting impression.' />
            <ServicepageHeadingRight subcontent='A strategy is more than only a plan to achieve goals. For us, it is a part of the definition of a product, given after we executed extensive market research and deeply analyzed customer needs.' list1='Unify your entire organizations' list2='Data into a single' list3='Centralized point of focus' />
          </Box>

          <Box className='box-cp service_cp'>
            <ul>
              <CapabilitiesBox CpIcon={service1} Cptitle='Sub-services 1' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service2} Cptitle='Sub-services 2' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service3} Cptitle='Sub-services 3' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service1} Cptitle='Sub-services 3' Cpinfo='Building creative solutions to complex problems.' />
            </ul>
          </Box>

          <Box className='serv-casestudy'>
            <Typography variant='h5' className='casestydyTitle' sx={{ textAlign: 'center' }}>Mobile App Design Case Studies</Typography>
            <ul className="portfolio-list">
              <ProjectBox
                portfoliImg={appimg}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
              <ProjectBox
                portfoliImg={web}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
              <ProjectBox
                portfoliImg={socialmediaapp}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
            </ul>
            <Box sx={{ textAlign: 'center' }}><Button variant='outlined' className='secondaryBtn'>View More<ArrowRightAltIcon /></Button></Box>
          </Box>
        </Box>
      </Box>

      <Box className='technoSection'>
        <Box className='main_conainer'>
          <Box className='technohead'>
            <ServicepageHeadingLeft headingicon={mobiIcon} headingTitle='Mobile App Design' Subtitle='We create software experiences that make a lasting impression.' />
            <ServicepageHeadingRight subcontent='A strategy is more than only a plan to achieve goals. For us, it is a part of the definition of a product, given after we executed extensive market research and deeply analyzed customer needs.' list1='Unify your entire organizations' list2='Data into a single' list3='Centralized point of focus' />
          </Box>

          <Box className='box-cp service_cp'>
            <ul>
              <CapabilitiesBox CpIcon={service1} Cptitle='Sub-services 1' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service2} Cptitle='Sub-services 2' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service3} Cptitle='Sub-services 3' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service1} Cptitle='Sub-services 3' Cpinfo='Building creative solutions to complex problems.' />
            </ul>
          </Box>

          <Box className='serv-casestudy'>
            <Typography variant='h5' className='casestydyTitle' sx={{ textAlign: 'center' }}>Mobile App Design Case Studies</Typography>
            <ul className="portfolio-list">
              <ProjectBox
                portfoliImg={appimg}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
              <ProjectBox
                portfoliImg={web}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
              <ProjectBox
                portfoliImg={socialmediaapp}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
            </ul>
            <Box sx={{ textAlign: 'center' }}><Button variant='outlined' className='secondaryBtn'>View More<ArrowRightAltIcon /></Button></Box>
          </Box>
        </Box>
      </Box>

      <Box className='technoSection'>
        <Box className='main_conainer'>
          <Box className='technohead'>
            <ServicepageHeadingLeft headingicon={mobiIcon} headingTitle='Mobile App Design' Subtitle='We create software experiences that make a lasting impression.' />
            <ServicepageHeadingRight subcontent='A strategy is more than only a plan to achieve goals. For us, it is a part of the definition of a product, given after we executed extensive market research and deeply analyzed customer needs.' list1='Unify your entire organizations' list2='Data into a single' list3='Centralized point of focus' />
          </Box>

          <Box className='box-cp service_cp'>
            <ul>
              <CapabilitiesBox CpIcon={service1} Cptitle='Sub-services 1' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service2} Cptitle='Sub-services 2' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service3} Cptitle='Sub-services 3' Cpinfo='Building creative solutions to complex problems.' />

              <CapabilitiesBox CpIcon={service1} Cptitle='Sub-services 3' Cpinfo='Building creative solutions to complex problems.' />
            </ul>
          </Box>

          <Box className='serv-casestudy'>
            <Typography variant='h5' className='casestydyTitle' sx={{ textAlign: 'center' }}>Mobile App Design Case Studies</Typography>
            <ul className="portfolio-list">
              <ProjectBox
                portfoliImg={appimg}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
              <ProjectBox
                portfoliImg={web}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
              <ProjectBox
                portfoliImg={socialmediaapp}
                Subtitle="Fintech Technology"
                Maintitle="Mobile app that takes electric scooters to the next level."
              />
            </ul>
            <Box sx={{ textAlign: 'center' }}><Button variant='outlined' className='secondaryBtn'>View More<ArrowRightAltIcon /></Button></Box>
          </Box>
        </Box>
      </Box>
      

      <Box className='main_conainer'>
        <AboutTop className title='Too early to take the first step?' buttonText='Schedule a call' />
      </Box>
      <Testimonials buttonText="Schedule a call" />
      <Box className="main_conainer bottomCards">
        <Box className="insideBox">
          <CardBox
            Ctitle="Rebrand"
            Cbody="Unlock the true Potential"
            BoxWidth="33%"
            CardClass="cardBox firstcard"
          />
          <CardBox
            Ctitle="New Idea"
            Cbody="Unlock the true Potential"
            BoxWidth="33%"
            CardClass="cardBox secondcard"
          />
          <CardBox
            Ctitle="Need Dedicated Resources?"
            Cbody="Unlock the true Potential"
            BoxWidth="33%"
            CardClass="cardBox thirdcard"
          />
        </Box>
      </Box>
    </Grid>
  );
}
