import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Margin } from '@mui/icons-material';

export default function AboutBottom(props) {
  return (
    <Grid className='aboutContent'>
        <Box className='left'>
            <Typography variant='h5' className='small-title'>{props.subTitle}</Typography>
            <Typography variant='h4' className='title'>{props.title}</Typography>
            <Typography variant='body1' className='Acaption'>{props.caption}</Typography>
            <Button variant='outlined' className='secondaryBtn'>{props.buttonText} <ArrowRightAltIcon/> </Button>
        </Box>
        <Box className='right'>
            <ul>
                <li>
                    <Typography variant='h6' className='aboutBigtext'>Reveal</Typography>
                    <Typography variant='body1'  className='about-text'>Decide & Design to Win in the Market.</Typography>
                </li>
                <li>
                    <Typography variant='h6' className='aboutBigtext'>Transform</Typography>
                    <Typography variant='body1'  className='about-text'>Develop & Deliver enthralling Digital Experiences.</Typography>
                </li>
                <li>
                    <Typography variant='h6' className='aboutBigtext'>Accelerate</Typography>
                    <Typography variant='body1'  className='about-text'>Unleash with the Power of Speed & Quality.</Typography>
                </li>
                <li>
                    <Typography variant='h6' className='aboutBigtext'>Optimize</Typography>
                    <Typography variant='body1'  className='about-text'>Constantly enhance the Digital approach.</Typography>
                </li>
            </ul>
        </Box>
    </Grid>
  );
}
