import { Box, Typography } from '@mui/material';
import React from 'react';

const SucsessStep = (props) => {
  return (
    <li>
        <Typography className='count'>{props.count}</Typography>
        <Box className='yearbox'>{props.year}</Box>
    </li>
  );
}

export default SucsessStep;
