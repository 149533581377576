import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const SectionHeading = (props) => {
  return (
    <Box className='section-heading'>
        <Typography variant='body1' className='small-title'>{props.Subtitle}</Typography>
        <Typography variant='h2' className='title'>{props.title}</Typography>
        <Typography className='detail'>{props.discriptions}</Typography>
    </Box>
  );
}

export default SectionHeading;
