import * as React from 'react';

import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { Button, Grid } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { FMenu } from '../utils/constants';


const FooterBar = () => {
  const [color, setColor] = React.useState('neutral');
  return (
    <Box>
      <Sheet
        variant="solid"
        color={color}
        invertedColors
        sx={{
          ...(color !== 'warning' && {
            backgroundColor: 'transparent', padding: '50px 0 50px 0',
          }),

        }}
      >


        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { md: 'flex-start' },
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <Box
            variant="soft"
            size="sm"
            sx={{
              flexDirection: { xs: 'row', md: 'column' },
              minWidth: { xs: '100%', md: 'auto' },
              gap: 1,
            }}
          >



            <Typography className='Fsubtitle' sx={{ color: '#8E8E8E', fontSize: '20px', }}>Excited with our services</Typography>
            <Typography className='footerTitle' level="h2" sx={{ maxWidth: '600px', }}>
              Let's build or improve your digital product.
            </Typography>

            <Box sx={{ display: 'flex', gap: '30px', margin: '30px 0 0 0', }}>
              <Button variant='outlined' className='secondaryBtn'>Schedule a call <ArrowRightAltIcon/></Button>
              <Button variant='outlined' className='secondaryBtn'>Contact us<ArrowRightAltIcon /></Button>
            </Box>

          </Box>

          <List
            size="sm"
            orientation="horizontal"
            wrap
            sx={{ flexGrow: 0, '--ListItem-radius': '8px' }}
          >
            <ListItem nested sx={{ width: { xs: '50%', md: 140, } }}>

              <List>
                {FMenu.map((Fitem) => (<ListItem>
                  <ListItemButton sx={{ fontFamily: 'Rubik', fontWeight: '500', fontSize: '16px', }}>{Fitem}</ListItemButton>
                </ListItem>)
                )}
               
              </List>
            </ListItem>
          </List>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            margin: '50px 0 0 0',
          }}
        >

          <Grid>
            <Typography className='contact-title'>Chat with us</Typography>
            <Typography className='contactNo'>+91 7726-998823</Typography>
          </Grid>

          <Grid>
            <Typography className='contact-title'>Email us</Typography>
            <Typography className='contactNo'>sales@lexicmedia.com</Typography>
          </Grid>

          <Grid>
            <Typography className='contact-title'>Find Us</Typography>
            <Typography className='contactNo'>+91 7726-998823</Typography>
          </Grid>

          <Grid>
            <Typography className='contact-title'>Location</Typography>
            <Typography className='contactNo'>B-41, Nehru Nagar, Jaipur, India (302016)</Typography>
          </Grid>
        </Box>
        <Box sx={{ margin: '30px 0 0 0', color: '#818181', fontSize: '12px', }}>© Copyright. Lexicmedia Company 2022-2023</Box>
      </Sheet>
    </Box>
  );
}

export default FooterBar;