import * as React from 'react';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { Box } from '@mui/material';
import { clsx } from 'clsx';

const CardBox = ({ Ctitle, Cbody, Cimg, CardClass, BoxWidth }) => {
  return (
    <Card
      
      sx={{
       
        maxWidth: '100%',
        width:`${BoxWidth}`,
        display:'inherit',
        height: 'auto',
        // to make the demo resizeable
        overflow: 'auto',
        resize: 'none',
        backgroundColor:'transparent',
        padding:0,
        boxShadow:'none',
        overflowY:'hidden',
        overflowX:'hidden',
      }}
    >
      <Box className={clsx(CardClass)} >

      <Box className='box-left'>
        <Typography level="h2">{Ctitle}</Typography>
        <Typography level="h3">
          {Cbody}</Typography>
      </Box>

      {Cimg && <Box className='box-right'> 
        <Box  component='img' src={Cimg} />
      </Box>}

      </Box>





    </Card>
  );
}

export default CardBox;