import React from 'react';
import SectionHeading from '../components/SectionHeading';
import ProjectBox from '../components/ProjectBox';
import { Box, Grid, Typography } from '@mui/material';
import ProfileTab from '../components/ProfileTab';
import MarqueeSection from '../components/MarqueeSection';
import Capabilities from '../components/Capabilities';
import DigitalExperience from '../components/DigitalExperience';
import JourneySection from '../components/JourneySection';
import Testimonials from '../components/Testimonials';
import CardBox from '../components/CardBox';
import proj1 from '../assets/images/proj1.png'
import proj2 from '../assets/images/proj2.png'
import proj3 from '../assets/images/proj3.png'
import proj4 from '../assets/images/proj4.png'

const Portfolio = () => {
    return (
        <Grid>
            <Grid className='main_conainer'>
                <Box className='top-space'>
                    <Box className='portfolio-sec'>
                        <SectionHeading Subtitle='Our Portfolio' title='Projects we delivered' />
                        <ProfileTab />
                    </Box>
                    <MarqueeSection SecTitle='Our clients love our creativity, passion and innovation.' />

                </Box>

            </Grid>
            <Capabilities cpSubtitle='Our Capabilities' cpMainTitle='Need a custom digital product? Let us help you!' buttonText='Browse Services' />
            <Box className='main_conainer'>
                <DigitalExperience />
                <JourneySection />
            </Box>
            <Testimonials buttonText='Schedule a call' />
            <Box className='main_conainer bottomCards'>
                <Box className='insideBox'>
                    <CardBox Ctitle='Rebrand' Cbody='Unlock the true Potential' BoxWidth='33%' CardClass='cardBox firstcard' />
                    <CardBox Ctitle='New Idea' Cbody='Unlock the true Potential' BoxWidth='33%' CardClass='cardBox secondcard' />
                    <CardBox Ctitle='Need Dedicated Resources?' Cbody='Unlock the true Potential' BoxWidth='33%' CardClass='cardBox thirdcard' />
                </Box>
            </Box>
        </Grid>
    );
}

export default Portfolio;
