import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

// const pages = [
//   {'Work':"/work"},
//  {'About Us':'/about'},
//   {'Portfolio':'/portfolio'},
//    {'Service':'service'},
//     {'contact Us':'/contactus'}
//   ];

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Box component="img" sx={{ width: 200 }} src={logo} alt="logo" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/">
                  {" "}
                  <Typography textAlign="center">Home </Typography>
                </Link>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/contactus">
                  {" "}
                  <Typography textAlign="center">Contactus </Typography>
                </Link>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/portfolio">
                  {" "}
                  <Typography textAlign="center">Portfolio </Typography>
                </Link>
              </MenuItem>

              {/* {pages.map((page) => (
                <>
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                 <Link to={Object.values(page)[0]}> <Typography textAlign="center">{`${Object.keys(page)[0]} /`}  </Typography></Link>
                </MenuItem> 
                </>
                
              ))} */}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Box component="img" sx={{ width: 100 }} src={logo} alt="logo" />
          </Typography>
          <Box
            className="navigation"
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", justifyContent: "center" },
            }}
          >
            <MenuItem onClick={handleCloseNavMenu}>
              <Link to="/">
                {" "}
                <Typography textAlign="center">Work </Typography>
              </Link>
            </MenuItem>

            <MenuItem onClick={handleCloseNavMenu}>
              <Link to="/aboutus">
                {" "}
                <Typography textAlign="center">About Us </Typography>
              </Link>
            </MenuItem>

            <MenuItem onClick={handleCloseNavMenu}>
              <Link to="/portfolio">
                {" "}
                <Typography textAlign="center">Portfolio</Typography>
              </Link>
            </MenuItem>

            <MenuItem onClick={handleCloseNavMenu}>
              <Link to="/service">
                {" "}
                <Typography textAlign="center">Service</Typography>
              </Link>
            </MenuItem>

            <MenuItem onClick={handleCloseNavMenu}>
              <Link to="/contactus">
                {" "}
                <Typography textAlign="center">Contact Us </Typography>
              </Link>
            </MenuItem>

            {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block', fontFamily:'Rubik', fontWeight:'400', fontSize:'18px',textTransform:'capitalize', }}
              >
                {page} /
              </Button>
            ))} */}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button
              className="primary-btn"
              variant="contained"
              sx={{
                fontFamily: "Rubik",
                fontWeight: "500",
                textTransform: "capitalize",
                padding: "15px",
              }}
            >
              Schedule a Call <ArrowRightAltIcon />
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
