import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import AboutTop from './AboutTop';
import SectionHeading from './SectionHeading';
import MarqueeSection from './MarqueeSection';

const JourneySection = () => {
  return (
    <Grid className='journy-mainBox'>
        <Box><AboutTop title='Too early to take the first step?' buttonText='Schedule a call'/></Box>
        
        <Box className='journy-heading'><SectionHeading  Subtitle='Testimonials' title='Here are some starts so far in Our 8 years of Journey'/></Box>
        <ul className='journy-reviewList'>
            <li>
                <Typography variant='h4' className='revTitle'>5.0</Typography>
                <Typography variant='body1' className='revdisc'>Clutch Review</Typography>
            </li>
            <li>
                <Typography variant='h4' className='revTitle'>2k+</Typography>
                <Typography variant='body1' className='revdisc'>Clients so far</Typography>
            </li>
            <li>
                <Typography variant='h4' className='revTitle'>40M</Typography>
                <Typography variant='body1' className='revdisc'>Design View</Typography>
            </li>
            <li>
                <Typography variant='h4' className='revTitle'>14+</Typography>
                <Typography variant='body1' className='revdisc'>Domain Worked</Typography>
            </li>
        </ul>

        <MarqueeSection SecTitle='Technologies we re focusing on'/>

    </Grid>
  );
}

export default JourneySection;
