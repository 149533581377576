import { Box, Button, Grid } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React from 'react';
import SectionHeading from './SectionHeading';
import TestimonialCard from './TestimonialCard';

const Testimonials = (props) => {
  return (
    <Grid className='testimonial-mainBox'>
        <Box className='main_conainer'>
        <Box className='inside-test'>
        <Box className='testmonial-left'>
        <SectionHeading Subtitle='Testimonials' title='Our 2k+ customers trust us' discriptions='We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.'/>
        <Button variant='outlined' className='secondaryBtn'>{props.buttonText} <ArrowRightAltIcon/></Button>
        </Box>

        <Box className='testmonial-right'>
            <TestimonialCard Treview='These guys are great. They really know their stuff, up to the minute technology and design. I can highly recommend them. Lovely people to work with.' username='Sean Rad' userDesignation='Lead Marketing Advisor'/>
        </Box>
        </Box>
        </Box>
    </Grid>
  );
}

export default Testimonials;
