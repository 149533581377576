import React from "react";
import HeroSection from "../components/Home/HeroSection";
import { Box, Grid, Typography } from "@mui/material";
import CardBox from "../components/CardBox";
import ProjectBox from "../components/ProjectBox";
import AboutUs from "../components/AboutUs";
import MarqueeSection from "../components/MarqueeSection";
import Capabilities from "../components/Capabilities";
import DigitalExperience from "../components/DigitalExperience";
import JourneySection from "../components/JourneySection";
import Testimonials from "../components/Testimonials";
import OWINKS0 from "../assets/images/OWINKS0.png";
import appimg from "../assets/images/app.png";
import socialmediaapp from "../assets/images/social-media-app.png";
import web from "../assets/images/web.png";
import mobiapp from "../assets/images/mobi-app.png";
import Lottie from "lottie-react";
import waveAnimation from "../assets/animations/wave.json";
import Piano from "../components/animations/Piano";

const Home = () => {
  return (
    <div>
      <Grid>
        <HeroSection>
          <div className="lottieanimation">
         
            <Lottie animationData={waveAnimation}></Lottie>
          </div>
          <Box className="main_conainer hero-box-outer">
            <CardBox
              Ctitle="A platform of mobility & intuitive web creatives"
              Cbody="Unlock the true Potential of Mobile & Web Solutions, catering to your Business & Serving the Industry Needs."
              Cimg={OWINKS0}
              BoxWidth="50%"
              CardClass="cardBox firstBox"
            />

            <CardBox
              Ctitle="Explore our marketplace"
              Cbody="Kickstart your business in no time with our ready-to- deliver products"
              BoxWidth="25%"
              CardClass="cardBox"
            />

            <CardBox
              Ctitle="AI based Solutions"
              Cbody="Potential of Mobile catering to your Business."
              BoxWidth="25%"
              CardClass="cardBox"
            />
          </Box>
        </HeroSection>
      </Grid>

      <Grid>
        <Box className="main_conainer">
          <section className="portfolio">
            <Typography className="small-title">Our Portfolio</Typography>
            <Typography variant="h4" className="title">
              Projects we delivered
            </Typography>
            <Box sx={{ display: "flex", gap: "60px", marginTop: "40px" }}>
              <ul className="portfolio-list">
                <ProjectBox
                  portfoliImg={appimg}
                  Subtitle="Fintech Technology"
                  Maintitle="Mobile app that takes electric scooters to the next level."
                />
                <ProjectBox
                  portfoliImg={socialmediaapp}
                  Subtitle="Fintech Technology"
                  Maintitle="Mobile app that takes electric scooters to the next level."
                />
              </ul>
              <ul className="portfolio-list">
                <ProjectBox
                  portfoliImg={web}
                  Subtitle="Fintech Technology"
                  Maintitle="Mobile app that takes electric scooters to the next level."
                  Liclass="secondLi"
                />
                <ProjectBox
                  portfoliImg={mobiapp}
                  Subtitle="Fintech Technology"
                  Maintitle="Mobile app that takes electric scooters to the next level."
                />
              </ul>
            </Box>
          </section>

          <AboutUs />

          <MarqueeSection SecTitle="Our clients love our creativity, passion and innovation." />
        </Box>

        <Capabilities
          cpSubtitle="Our Capabilities"
          cpMainTitle="Need a custom digital product? Let us help you!"
        />

        <Box className="main_conainer">
          <DigitalExperience />

          <JourneySection />
        </Box>
        <Testimonials buttonText="Schedule a call" />

        <Box className="main_conainer bottomCards">
          <Box className="insideBox">
            <CardBox
              Ctitle="Rebrand"
              Cbody="Unlock the true Potential"
              BoxWidth="33%"
              CardClass="cardBox firstcard"
            />
            <CardBox
              Ctitle="New Idea"
              Cbody="Unlock the true Potential"
              BoxWidth="33%"
              CardClass="cardBox secondcard"
            />
            <CardBox
              Ctitle="Need Dedicated Resources?"
              Cbody="Unlock the true Potential"
              BoxWidth="33%"
              CardClass="cardBox thirdcard"
            />
          </Box>
        </Box>
      </Grid>
    </div>
  );
};

export default Home;
