import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./animations/styles.css"; // Import your CSS styles
import { Box, Grid, Typography } from "@mui/material";
import mr1 from "../assets/images/mr1.png";
import mr2 from "../assets/images/mr2.png";
import mr3 from "../assets/images/mr3.png";
import mr4 from "../assets/images/mr4.png";
import mr5 from "../assets/images/mr5.png";
import mr6 from "../assets/images/mr6.png";

gsap.registerPlugin(ScrollTrigger);

function MarqueeSection(props) {
  const marqueeRef = useRef(null);

  useEffect(() => {
    const marquee = marqueeRef.current;

    // Ensure that the marquee element and its content are available
    if (!marquee) return;

    const marqueeContent = marquee.querySelector(".marquee-content");
    const marqueeImages = marqueeContent.querySelectorAll("li");

    // Calculate the width of a single marquee item (image)
    const itemWidth = marqueeImages[0].offsetWidth;

    // Calculate the total width of the marquee
    const totalWidth = itemWidth * marqueeImages.length;

    // Set the marquee content width to accommodate all items
    marqueeContent.style.width = `${totalWidth}px`;

    // Create a timeline for the animation
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: marquee,
        scrub: 1,
        direction: 'right',
        pin: false,
        start: "top top",
        end: () => `+=${totalWidth}`, // Scroll duration based on marquee width
      },
    });

    // Animate the marquee
    tl.to(marqueeContent, {
      x: -totalWidth,
      duration: totalWidth / 100, // Adjust the duration as needed
      ease: "linear",
      repeat: -1,
    });
  }, []);

  return (
    <div className="marquee-sec" ref={marqueeRef}>
      <Typography variant="caption" className="mrTitle">
        {props.SecTitle}
      </Typography>
      <Box className="MarResult">
        <div className="marquee-container">
          <div className="marquee-content">
            <ul>
              <li>
                <img src={mr1} alt="" />
              </li>
              <li>
                <img src={mr2} alt="" />
              </li>
              <li>
                <img src={mr3} alt="" />
              </li>
              <li>
                <img src={mr4} alt="" />
              </li>
              <li>
                <img src={mr5} alt="" />
              </li>
              <li>
                <img src={mr6} alt="" />
              </li>
              <li>
                <img src={mr5} alt="" />
              </li>
              <li>
                <img src={mr6} alt="" />
              </li>
            </ul>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default MarqueeSection;
