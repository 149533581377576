import React from 'react';
import FooterBar from '../components/FooterBar';

const Footer = () => {
  return (
    <div className='footer-outer'>
    <div  className='main_conainer'>
      <FooterBar/>
    </div>
    </div>
  );
}

export default Footer;
