// import logo from './logo.svg';
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import SiteRoutes from "./routes/SiteRoutes";

function App() {
  return (
    <>
      <BrowserRouter>
        <SiteRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
