import { Box, Grid } from '@mui/material';
import React from 'react';
import MarketTab from '../components/MarketTab';
import AboutTop from '../components/AboutTop';
import Testimonials from '../components/Testimonials';
import CardBox from '../components/CardBox';

export default function Marketplace() {
    return (
        <Grid>
            <Box className='market-hero'>
                <Box className='main_conainer'>
                    <small className='small-title'>Markpetplace</small>
                    <h1 className='title'>Ingenious products driven by transparent relationships</h1>
                    <p className='small-disc'>delivering highly productive and cost-effective applications across various domains.</p>
                </Box>
            </Box>
            <Grid className='main_conainer'>
               <Box className='marketTab'>
                <MarketTab/>
                <AboutTop title='Too early to take the first step?' buttonText='Schedule a call'/>
               </Box>
            </Grid>
            <Testimonials buttonText="Schedule a call" />

            <Box className="main_conainer bottomCards">
          <Box className="insideBox">
            <CardBox
              Ctitle="Rebrand"
              Cbody="Unlock the true Potential"
              BoxWidth="33%"
              CardClass="cardBox firstcard"
            />
            <CardBox
              Ctitle="New Idea"
              Cbody="Unlock the true Potential"
              BoxWidth="33%"
              CardClass="cardBox secondcard"
            />
            <CardBox
              Ctitle="Need Dedicated Resources?"
              Cbody="Unlock the true Potential"
              BoxWidth="33%"
              CardClass="cardBox thirdcard"
            />
          </Box>
        </Box>

        </Grid>
    );
}
