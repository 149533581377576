import { Typography } from '@mui/material';
import React from 'react';

const ExperienceNumber = (props) => {
  return (
    
        <li>
            <a href='/'>
                <Typography className='number' variant='body1'>{props.number}</Typography>
                <Typography className='heading' variant='caption'>{props.heading}</Typography>
                <Typography className='disc' variant='body2'>{props.discription}</Typography>
            </a>
        </li>
  );
}

export default ExperienceNumber;
