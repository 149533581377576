import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";

const SiteLayout = () => {
  return (
    <div>
      <Box>
        <Header />
      </Box>

      <Grid>
        <Outlet />
      </Grid>

      <Box>
        <Footer />
      </Box>
    </div>
  );
};

export default SiteLayout;
