import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Import CSS styles here if needed

function ScrollingText({ text }) {
  gsap.registerPlugin(ScrollTrigger);

  const scrollingTextRef = useRef(null);

  useEffect(() => {
    const tl2 = gsap.timeline();
    tl2.to(scrollingTextRef.current, {
      x:1000,
      duration:50,
      repeat:-1,
      scrub:1,
      ease:'linear',
      pin: true,
    });

    const tl = gsap.timeline();
    tl.to(scrollingTextRef.current, {
      xPercent: 15,
      scrollTrigger: {
        trigger: scrollingTextRef.current,
        scrub: 1,
      },
    });
  }, []);

  return (
    <section className="spacer">
      <h1 ref={scrollingTextRef}>{text}</h1>
    </section>
  );
}

export default ScrollingText;
