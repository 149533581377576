import { Box, Typography } from '@mui/material';
import React from 'react';

const CapabilitiesBox = ({CpIcon,Cptitle,Cpinfo}) => {
  return (
      
        <li>
            <Box component='img' src={CpIcon} />
            <Typography variant='body1' className='cbox-title' >{Cptitle}</Typography>
            <Typography variant='body2' className='cbox-info' >{Cpinfo}</Typography>
        </li>
        
  );
}

export default CapabilitiesBox;
