import { Box, Typography } from '@mui/material';
import React from 'react';

export default function ServicepageHeadingLeft({headingicon,headingTitle, Subtitle}) {
  return (
      <Box className='headingleft'>
        <Box component='img' src={headingicon} alt=''></Box>
        <Typography className='headingtitle' variant='h2'>{headingTitle}</Typography>
        <Typography className='headsubtitle' variant='body1'>{Subtitle}</Typography>
        </Box>
  );
}
