import { Box, Typography } from '@mui/material';
import React from 'react';

const DigitalExperienceListBox = ({dgIcon,dgtitle,dginfo}) => {
  return (
      <li>
      <Box component='img' src={dgIcon} />
      <Typography variant='h3' className='dgbox-title' >{dgtitle}</Typography>
      <Typography variant='body2' className='dgbox-info' >{dginfo}</Typography>
      </li>
  );
}

export default DigitalExperienceListBox;
