import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import CapabilitiesBox from './CapabilitiesBox';
import AboutTop from './AboutTop';
import BlueMarquee from './BlueMarquee';
import appicon from '../assets/images/app-icon.png'
import ecommerce from '../assets/images/ecommerce.png'
import design from '../assets/images/design.png'

const Capabilities = (props) => {
  return (
    
    <Grid className='capability-sec'>
        <Box className='main_conainer'>
            <Typography className='small-title'>{props.cpSubtitle}</Typography>
            <Typography variant='h2' className='title'>{props.cpMainTitle}</Typography>
            <Box className='box-cp'>
            <ul>
            <CapabilitiesBox CpIcon={appicon} Cptitle='Mobile App' Cpinfo='Building creative solutions to complex problems.' />

            <CapabilitiesBox CpIcon={ecommerce} Cptitle='Ecommerce' Cpinfo='Building creative solutions to complex problems.' />
            
            <CapabilitiesBox CpIcon={design} Cptitle='Design' Cpinfo='Building creative solutions to complex problems.' />

            <CapabilitiesBox CpIcon={appicon} Cptitle='Optimization' Cpinfo='Building creative solutions to complex problems.' />
            </ul>
             </Box>
                {/* <Box className='cp-mrque'> <MarqueeSection SecTitle='Fundamental presence in all core sectors'/></Box> */}
                <Box className='cp-mrque'> 
                <BlueMarquee SecTitle='Fundamental presence in all core sectors'/>
                </Box>
                <Box className='cp-Abouttop'><AboutTop title='What can we help you with?' buttonText='buttonText'/></Box>

        </Box>
    </Grid>
  );
}

export default Capabilities;
