import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProjectBox from './ProjectBox';
import appimg from '../assets/images/app.png'
import socialmediaapp from '../assets/images/social-media-app.png'
import web from '../assets/images/web.png'
import mobiapp from '../assets/images/mobi-app.png'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 7 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tabscenter'>
          <Tab label="Health" {...a11yProps(0)} />
          <Tab label="Finance" {...a11yProps(1)} />
          <Tab label="Enterprise" {...a11yProps(2)} />
          <Tab label="Ecommerce" {...a11yProps(3)} />
          <Tab label="Automotive" {...a11yProps(4)} />
          <Tab label="Education" {...a11yProps(5)} />
          <Tab label="Social Media" {...a11yProps(6)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ display: 'flex', gap: '60px', marginTop: '40px', }}>
          <ul className='portfolio-list'>
            <ProjectBox portfoliImg={appimg} Subtitle='Fintech Technology' Maintitle='Mobile app that takes electric scooters to the next level.' />
            <ProjectBox portfoliImg={socialmediaapp} Subtitle='Fintech Technology' Maintitle='Mobile app that takes electric scooters to the next level.' />

          </ul>
          <ul className='portfolio-list'>
            <ProjectBox portfoliImg={web} Subtitle='Fintech Technology' Maintitle='Mobile app that takes electric scooters to the next level.' Liclass='secondLi' />
            <ProjectBox portfoliImg={mobiapp} Subtitle='Fintech Technology' Maintitle='Mobile app that takes electric scooters to the next level.' />
          </ul>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        Item Four
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        Item Five
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        Item Six
      </CustomTabPanel>
    </Box>
  );
}