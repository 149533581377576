import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Contactus from '../pages/Contactus';
import Portfolio from '../pages/Portfolio';
import SiteLayout from '../layout/SiteLayout';
import AboutUs from '../pages/AboutUs';
import Marketplace from '../pages/Marketplace';
import Service from '../pages/Service';

const SiteRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SiteLayout />} >
        <Route index path="/" element={<Home />} />
        <Route index path="/home" element={<Home />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/service" element={<Service />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/marketplace" element={<Marketplace />} />
      </Route>
    </Routes>
  );
}

export default SiteRoutes;
