import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ProjectBox from '../ProjectBox';
import appimg from "../../assets/images/app.png";
import socialmediaapp from "../../assets/images/social-media-app.png";
import web from "../../assets/images/web.png";
import mobiapp from "../../assets/images/mobi-app.png";
import { Box } from '@mui/material';

gsap.registerPlugin(ScrollTrigger);

function PortfolioComponent() {
  const pianoRef = useRef(null);

  useEffect(() => {
    const piano = pianoRef.current;
    const keys = piano.querySelectorAll('.key');

    gsap.set(keys, {
      opacity: 0,
      y: '30%',
    });

    ScrollTrigger.batch(keys, {
      onEnter: (batch) =>
        gsap.to(batch, {
          opacity: 1,
          y: 0,
          stagger: {
            each: 0.25,
            from: 'start',
          },
        }),
    });

    ScrollTrigger.addEventListener('refreshInit', () =>
      gsap.set(keys, {
        opacity: 0,
        y: '30%',
      })
    );
  }, []);

  const PortfolioBox = [
    {
      portfoliImg: appimg,
      Subtitle: 'Fintech Technology',
      Maintitle: "Mobile app that takes electric scooters to the next level.",
      Liclass: "Key"
    },
    {
      portfoliImg: socialmediaapp,
      Subtitle: 'Fintech Technology',
      Maintitle: "Mobile app that takes electric scooters to the next level.",
      Liclass: "Key"
    },
    {
      portfoliImg: web,
      Subtitle: 'Fintech Technology',
      Maintitle: "Mobile app that takes electric scooters to the next level.",
      Liclass: "Key"
    },
    {
      portfoliImg: mobiapp,
      Subtitle: 'Fintech Technology',
      Maintitle: "Mobile app that takes electric scooters to the next level.",
      Liclass: "Key"
    }
  ];

  return (
    <Box className="piano" ref={pianoRef} sx={{ display: "flex", gap: "60px", marginTop: "40px" }}>
      <ul className="portfolio-list">
        {PortfolioBox.map((pBox, index) => (
          <ProjectBox
            key={index}
            portfoliImg={pBox.portfoliImg}
            Subtitle={pBox.Subtitle}
            Maintitle={pBox.Maintitle}
            Liclass={pBox.Liclass}
          />
        ))}
      </ul>
    </Box>
  );
}

export default PortfolioComponent;
