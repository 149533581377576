import { Box, Button, Grid, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';
export default function WebAppComp({webappImg, webtitle,subtitle,discription,sum1,sum2,sum3,beni1,beni2,beni3}) {
    return (
        <Grid className='webapp-box'>
            <Box className='webapp-img' component='img' src={webappImg} />
            <Box className='webapp-detail'>
                <Typography className='webapp-Title'>{webtitle}</Typography>
                <ul className='appsumry'>
                    <li>{sum1}</li>
                    <li>{sum2}</li>
                    <li>{sum3}</li>
                </ul>

                <ul className='webapp-benifits'>
                    <li><CheckCircleIcon/>{beni1}</li>
                    <li><CheckCircleIcon/>{beni2}</li>
                    <li><CheckCircleIcon/>{beni3}</li>
                </ul>

                <Box className='rating'>
                    <span><StarIcon/></span>
                    <span><StarIcon/></span>
                    <span><StarIcon/></span>
                    <span><StarIcon/></span>
                    <span><StarIcon/></span>
                </Box>

                <Button variant='contained' className='primary-btn' sx={{ padding: '15px', margin: '30px 0 0 0', }}>Download <ArrowRightAltIcon /></Button>
            </Box>
        </Grid>
    );
}
