import React from 'react';
import Navbar from '../components/Navbar';

const Header = () => {
  return (
    <div className='header headerbg'>
        <Navbar/>
    </div>
  );
}

export default Header;
