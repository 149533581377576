import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import SectionHeading from '../components/SectionHeading';
import Testimonials from '../components/Testimonials';
import CardBox from '../components/CardBox';
import JourneySection from '../components/JourneySection';
import ExperienceNumber from '../components/ExperienceNumber';
import SucsessStep from '../components/SucsessStep';
import AboutTop from '../components/AboutTop';
import aboutjpg from '../assets/images/aboutus.jpg';
import appicon from '../assets/images/app-icon.png'
import flag from '../assets/images/flag.png';
import smile from '../assets/images/smile.png';
import ScrollingText from '../components/animations/ScrollingText';


const AboutUs = () => {
    return (
        <Grid>
            <Grid className='main_conainer'>
                <Box className='top-space'>
                    <SectionHeading Subtitle='About Us' title='Ingenious products driven by transparent relationships' discriptions='delivering highly productive and cost-effective applications across various domains.' />
                    <Typography className='aboutimg' variant='body1'><img src={aboutjpg} alt='' /></Typography>
                    <JourneySection />

                </Box>
            </Grid>

            <Box className='loop-content'>
            <ScrollingText text="Discovery. Strategy. Discovery Discovery. Strategy. Discovery" />
                {/* <Typography className='looptext'>Discovery. Strategy. Discovery</Typography> */}
            </Box>

            <Box className='whyus'>
                <Box className='main_conainer'>
                    <SectionHeading Subtitle='Why us' title='Create, design & deliver real experiences' discriptions='We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.' />

                    <ul className='expbox'>
                        <ExperienceNumber number='1' heading='Reveal' discription='Decide & Design to Win in the Market.' />
                        <ExperienceNumber number='2' heading='Accelerate' discription='Unleash with the Power of Speed & Quality.' />
                        <ExperienceNumber number='3' heading='Transform' discription='Develop & Deliver enthralling Digital Experiences.' />
                        <ExperienceNumber number='4' heading='Optimize' discription='Constantly enhance the Digital approach.' />
                    </ul>
                </Box>
            </Box>

            <Box className='coreValue-sec'>
                <Box className='main_conainer valuesec-inside'>
                    <Box className='value-left'>
                        <SectionHeading Subtitle='- Core values' title='Our Strategy & Execution' discriptions='We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.' />
                    </Box>
                    <Box className='value-right'>
                        <ul>
                            <li>
                                <Box className='icon'><img src={appicon} alt='' /></Box>
                                <Box className='content'>
                                    <Typography className='title' variant='h6'>Amazing Experience</Typography>
                                    <Typography className='discrip' variant='body1'>We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.</Typography>
                                </Box>
                            </li>
                            <li>
                                <Box className='icon'><img src={appicon} alt='' /></Box>
                                <Box className='content'>
                                    <Typography className='title' variant='h6'>Work Ideology</Typography>
                                    <Typography className='discrip' variant='body1'>We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.</Typography>
                                </Box>
                            </li>
                            <li>
                                <Box className='icon'><img src={appicon} alt='' /></Box>
                                <Box className='content'>
                                    <Typography className='title' variant='h6'>Business Innovation</Typography>
                                    <Typography className='discrip' variant='body1'>We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.</Typography>
                                </Box>
                            </li>
                        </ul>
                    </Box>
                </Box>
            </Box>

            <Box className='jorny-sec'>
                <Box className='main_conainer'>
            <SectionHeading Subtitle='- Our Jounney' title='Here are some starts so far in Our 8 years of Journey' discriptions='We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.' />
            <ul className='steps'>
                <SucsessStep count='Client : 7' year='2016' />
                <SucsessStep count='Client : 14' year='2017' />
                <SucsessStep count='Client : 14' year='2017' />
                <SucsessStep count='Client : 14' year='2017' />
                <SucsessStep count='Client : 14' year='2017' />
                <SucsessStep count='Client : 14' year='2023' />
            </ul>
            <Box className='steptext'>
            <Typography className='start'><img src={flag} alt=''/> START</Typography>
            <Typography className='sucsess'>SUCCESS <img src={smile} alt=''/></Typography>
            </Box>
            </Box>
            </Box>
            <Box className='main_conainer'>
            <AboutTop title='Too early to take the first step?' buttonText='Schedule a call' />
            </Box>
            <Testimonials buttonText='Schedule a call' />
            <Box className='main_conainer bottomCards'>
                <Box className='insideBox'>
                    <CardBox Ctitle='Rebrand' Cbody='Unlock the true Potential' BoxWidth='33%' CardClass='cardBox firstcard' />
                    <CardBox Ctitle='New Idea' Cbody='Unlock the true Potential' BoxWidth='33%' CardClass='cardBox secondcard' />
                    <CardBox Ctitle='Need Dedicated Resources?' Cbody='Unlock the true Potential' BoxWidth='33%' CardClass='cardBox thirdcard' />
                </Box>
            </Box>
        </Grid>
    );
}

export default AboutUs;
