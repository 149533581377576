import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import DigitalExperienceListBox from './DigitalExperienceListBox';
import SectionHeading from './SectionHeading';
import face from '../assets/images/face.svg'

const DigitalExperience = () => {
  return (
   <>
   <Grid className='dgSection'>
    <SectionHeading Subtitle='We are process nerds' title='Get your desired digital experience' discriptions='We don’t aim for the big fame, rather we establish stable and truly reliable brands, each proven as a leading name in its own niche.'/>
    
    <Box className='gdlist'>
        <ul>
            <DigitalExperienceListBox dgIcon={face} dgtitle='Multiple Consultation Sessions' dginfo='Our goal is to provide you with complete transparency.'/>
            <DigitalExperienceListBox dgIcon={face} dgtitle='Multiple Consultation Sessions' dginfo='Our goal is to provide you with complete transparency.'/>
            <DigitalExperienceListBox dgIcon={face} dgtitle='Multiple Consultation Sessions' dginfo='Our goal is to provide you with complete transparency.'/>
            <DigitalExperienceListBox dgIcon={face} dgtitle='Multiple Consultation Sessions' dginfo='Our goal is to provide you with complete transparency.'/>
        </ul>
    </Box>
   </Grid>
   </>
  );
}

export default DigitalExperience;
